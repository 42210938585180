import React, {useEffect, useState} from 'react';
import {Menu} from "grevlingui";
import {Modal} from "../../General/Modal/Modal";
import {generateUuidv4} from "../../../utils/utils";
import moment from "moment";
import {Session} from "./Session/Session";
import {AddModal} from "./AddModal";
import {SortableList} from "../../SortableList/SortableList";
import {AddButton} from "../../General/AddButton";

const getThings = (key) => {
    const stored = localStorage.getItem(key)
    if (stored)
        return JSON.parse(stored);
    return [];
}

export const Workout = () => {
    const [addingType, setAddingType] = useState(false);
    const [deletingType, setDeletingType] = useState(undefined);
    const [types, setTypes] = useState(getThings('telleting-workout-types'));
    const [sessions, setSessions] = useState(getThings('telleting-sessions'));

    const deleteSession = si => () => {
        setSessions(oldSessions => [
            ...oldSessions
        ].filter((_, i) => i !== si))
    }

    const getSetSessionDate = si => date => {
        setSessions(oldSessions => {
            const newSessions = [...oldSessions];
            newSessions[si] = {
                ...newSessions[si],
                date
            }
            return newSessions;
        });
    }

    const getSetExercises = si => exercises => {
        setSessions(oldSessions => {
            const newSessions = [...oldSessions];
            newSessions[si] = {
                ...newSessions[si],
                exercises
            }
            return newSessions;
        });
    }

    const getUpdateExercise = si => (e, ei) => {
        setSessions(oldSessions => {
            const newSessions = [...oldSessions];
            const exercises = [...newSessions[si].exercises];
            exercises[ei] = e;
            newSessions[si] = {
                ...newSessions[si],
                exercises
            }
            return newSessions;
        });
    }

    const getAddExercise = si => (name, onSuccess) => {
        if (!name || name.length === 0) return;
        setSessions(oldSessions => {
            const newSessions = [...oldSessions];
            const exercises = [...newSessions[si].exercises, {name}];
            console.log('exercises: ', exercises);
            newSessions[si] = {
                ...newSessions[si],
                exercises
            }
            return newSessions;
        });
        onSuccess();
    }

    const addSession = () => {
        const exercises = types.map(name => ({name}));
        const newWorkout = {
            id: generateUuidv4(),
            date: moment().startOf('day'),
            new: true,
            exercises
        };

        setSessions(oldWorkouts => [
            newWorkout,
            ...oldWorkouts
        ])
    }

    useEffect(() => {
        if (types)
            localStorage.setItem('telleting-workout-types', JSON.stringify(types));
    }, [types]);

    useEffect(() => {
        if (sessions)
            localStorage.setItem('telleting-sessions', JSON.stringify(sessions));
    }, [sessions]);

    const addType = (newTypeName) => {
        if (newTypeName.length === 0)
            return;
        setAddingType(false);
        setTypes(oldTypes => [
            ...oldTypes,
            newTypeName
        ]);
    }

    const confirmDelete = () => {
        setTypes(oldTypes => oldTypes.filter(ot => ot !== deletingType));
        setDeletingType(undefined);
    }

    const modal = addingType ? (
        <AddModal
            heading="Ny fast øvelse:"
            close={() => setAddingType(false)}
            confirm={addType}
        />
        ) : deletingType ? (<Modal close={() => setDeletingType(undefined)}>
        <p>Slette {deletingType}?</p>
        <div className="buttonGroup">
            <button
                className="rounded thing"
                onClick={confirmDelete}
            >
                Jepp
            </button>
            <button
                className="rounded thing"
                onClick={() => setDeletingType(undefined)}
            >
                Nei
            </button>
        </div>
    </Modal>) : undefined;

    const sortSessions = (s1, s2) => {
        const m1 = moment(s1.date).valueOf();
        const m2 = moment(s2.date).valueOf();
        return m2 - m1;
    }

    return (
        <div className="workout">
            <h1>Trening</h1>
            <div className="sessions">
                <AddButton onClick={addSession}/>
                {(sessions ?? [])
                    .sort(sortSessions)
                    .map((s, i) => (
                        <Session
                            key={s.id}
                            session={s}
                            setExercises={getSetExercises(i)}
                            updateExercise={getUpdateExercise(i)}
                            setSessionDate={getSetSessionDate(i)}
                            deleteSession={deleteSession(i)}
                            addExercise={getAddExercise(i)}
                            sessionIndex={i}
                            sessions={sessions}
                            old={() => {
                                setSessions(prev => {
                                    const newArray = [...prev];
                                    const newElement = {...newArray[i]};
                                    newArray[i] = newElement;
                                    delete newElement.new;
                                    return newArray;
                                })
                            }}
                        />
                    ))}
            </div>
            <Menu
                settings left
                color={'white'}
                bgColor={'var(--secondary)'}
                menuBgColor={'var(--secondary)'}
            >
                <p className="heading">Faste øvelser</p>
                <div className="types">
                    <SortableList
                        items={types}
                        setItems={setTypes}
                        getId={t => t}
                        renderItem={t => (
                            <div
                                key={t}
                                className="type rounded thing"
                                onClick={() => setDeletingType(t)}
                            >
                                {t}
                            </div>
                        )}
                    />
                </div>
                <div className="tricky thing">
                    <AddButton
                        onClick={() => setAddingType(true)}
                    />
                </div>
                {modal}
            </Menu>
        </div>
    );
}