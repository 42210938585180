import React, {useState} from 'react';
import 'react-dates/initialize';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Menu} from "./App/Menu/Menu";
import {FourOhFour} from "./App/General/FourOhFour";
import {NewCount} from "./App/Pages/NewCount/NewCount";
import {Count} from "./App/Pages/Count/Count";
import useEffectOnce from "./utils/UseEffectOnce";
import {firebaseGet} from "./App/FireBase/firebase";
import {Workout} from "./App/Pages/Workout/Workout";
import {AddButton} from "./App/General/AddButton";

function App() {
    const [counts, setCounts] = useState([]);

    const getCounts = () => {
        let countIds = localStorage.getItem('counts');
        countIds = countIds ? JSON.parse(countIds) : [];
        firebaseGet(['counts'])
            .then(val => {
                if (!val) return;
                const thing = Object.values(val)
                    .filter(c => countIds.includes(c.id))
                setCounts(thing);
            })
    }

    useEffectOnce(getCounts);

    const renderLink = (label, url) => (
        <Link
            key={label}
            className={'rounded thing'}
            to={url}
        >{label}</Link>
    )

    const renderMenu = () => (
        <Menu
            bgColor={'var(--secondary)'}
            menuBgColor={'var(--secondary)'}
        >
            {renderLink('Trening', '/')}
            {counts.map(count => renderLink(count.name, `/counts/${count.id}`))}
            <Link to={'/new-count'}>
                <AddButton/>
            </Link>
        </Menu>
    );

    const p = 0.02;
    const reality = Math.random();
    const blue_moon = p > reality;

    return (
        <BrowserRouter>
            <div className={`App${blue_moon ? ' blue_moon' : ''}`}>
                <div className={`app__content`}>
                    {renderMenu()}
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (
                                <Workout/>
                                )}
                        />
                        <Route
                            exact
                            path="/new-count"
                            render={(router) => (
                                <NewCount
                                    router={router}
                                    onChange={getCounts}
                                />
                            )}
                        />
                        <Route
                            path="/counts/:id"
                            render={(router) => (
                                <Count
                                    router={router}
                                    onDelete={getCounts}
                            />
                            )}
                        />
                        <Route
                            path="/"
                            render={() => (<FourOhFour/>)}
                        />
                    </Switch>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
